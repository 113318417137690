import type React from 'react'
import { useEffect, useState } from 'react'
import { type ColorVariant, colorVariantClasses } from '../ColorVariant/variant'

type BasicButtonProps = {
  colorVariant?: ColorVariant
  bg?: string // 背景色のクラス名
  icon?: string // FontAwesomeのアイコンクラス名
  label: string // ボタンのラベル
  largeSize?: boolean
  className?: string // オプションでclassNameを受け取る
  onClick?: () => void // ボタンがクリックされたときの処理
  disabled?: boolean
  tabIndex?: number
}

const BasicButton: React.FC<BasicButtonProps> = ({
  colorVariant,
  bg,
  icon,
  label,
  largeSize = false,
  className,
  onClick,
  disabled = false,
  tabIndex = 0,
}) => {
  const [isKeyboardFocus, setIsKeyboardFocus] = useState(false)
  const handleEnterKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      onClick?.()
    }
  }
  const colorVariantClass =
    colorVariantClasses[disabled ? 'disabled' : (colorVariant as ColorVariant)]

  useEffect(() => {
    const handleTabKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        setIsKeyboardFocus(true)
      }
    }

    const handleMouseDown = () => {
      setIsKeyboardFocus(false)
    }

    document.addEventListener('keydown', handleTabKeyDown)
    document.addEventListener('mousedown', handleMouseDown)

    return () => {
      document.removeEventListener('keydown', handleTabKeyDown)
      document.removeEventListener('mousedown', handleMouseDown)
    }
  }, [])

  return (
    <div
      className={`${
        !colorVariantClass ? bg : ''
      } cursor-pointer inline-flex items-center justify-center gap-2 rounded-[10px] ${className} ${colorVariantClass} ${
        largeSize ? 'px-4 py-3.5 text-[15px]' : 'px-3 py-2.5 text-sm'
      } ${isKeyboardFocus ? 'focus-keyboard' : ''}`}
      onClick={onClick}
      onKeyDown={handleEnterKeyDown}
      tabIndex={disabled ? -1 : tabIndex}
      role='button'
    >
      {icon && (
        <span
          className={`${icon} relative flex items-center justify-center icon-before text-[14px] w-[14px] h-[14px]`}
        />
      )}
      <span className='flex items-center font-semibold pt-0.5'>{label}</span>
    </div>
  )
}

export default BasicButton
